import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Web3 from 'web3';


const Connectwallet = ({show,handleClose}) => {
    const [accounts, setAccounts] = useState([]);

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const web3 = new Web3(window.ethereum);
                const accs = await web3.eth.getAccounts();
                setAccounts(accs[0]);
            } catch (error) {
                console.error('Error connecting to Wallet:', error);
            }
        } else {
            console.error('Wallet not detected');
        }
    };
  return (
    <>
    <Modal size='sm' className='cmn_modal' centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
       
        <Modal.Body>
        <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title'>Connect Wallet</p>
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>

            <div className='d-flex justify-content-center flex-column align-items-center mt-4 cmnModal__walletHolder' onClick={() => {connectWallet();handleClose();}}>
                <img src={require('../assets/images/metamask.svg').default} className='img-fluid cnctWallet__metamask' />
                <p className='cmn_modal_title mt-2'>Metamask</p>
            </div>
         
        </Modal.Body>
        
      </Modal>
    </>
  )
}

export default Connectwallet