import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { addNewExchange } from "../actions/currency";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import Select from "react-select";
import { CheckFactory } from "../Hooks/useWeb3";
import toast from "react-hot-toast";
const AddExchangeModal = ({ show, handleClose, getAllExchanges }) => {
  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const options = [
    { value: 56, label: "56" },
    // { value: 137, label: "137" },
  ];

  const options1 = [
    { value: "v2", label: "V2" },
    { value: "v3", label: "V3" },
  ];

  const addressRegex = "^(0x)[0-9A-Fa-f]{40}$";
  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      // if (name == "router") {
      //   var pattern = new RegExp(addressRegex);
      //   if (!pattern.test(value)) {
      //     return;
      //   }
      // }
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      if (name == "type") return;

      let fact = await CheckFactory(e.target.value, selectedOption2.value);

      if (fact.status == true) {
        Formvalue = { ...Formvalue, ["chainId"]: selectedOption2.value };
        Formvalue = {
          ...Formvalue,
          ["factory"]: fact.data,
        };
        setFormvalue(Formvalue);
        let error = { ...errors };
        error[`${name}`] = "";
        setErrors(error);
      } else {
        Formvalue = { ...Formvalue, ["chainId"]: "" };
        Formvalue = {
          ...Formvalue,
          ["factory"]: "",
        };
        setFormvalue(Formvalue);

        let error = { ...errors };
        error = { ...error, ["router"]: "Enter a valid router address" };
        setErrors(error);
      }

      // let error = { ...errors };
      // error[`${name}`] = "";
      // setErrors(error);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const [selectedOption2, setSelectedOption2] = useState();

  const handleChange2 = async (selectedOption2) => {
    const { name, value } = selectedOption2;
    // let Formvalue = { ...formvalue, ["chainId"]: value };

    let fact = await CheckFactory(formvalue.router, selectedOption2.value);
    let Formvalue = { ...formvalue };
    if (fact.status == true) {
      Formvalue = { ...Formvalue, ["chainId"]: value };
      Formvalue = {
        ...Formvalue,
        ["factory"]: fact.data,
      };
      setFormvalue(Formvalue);
      setSelectedOption2(selectedOption2);
    } else {
      let error = { ...errors };
      error = { ...error, ["router"]: "Enter a valid router address" };
      setErrors(error);
    }
  };

  const [selectedOption3, setSelectedOption3] = useState();

  const handleChange3 = async (selectedOption3) => {
    const { name, value } = selectedOption3;
    let Formvalue = { ...formvalue, ['type']: value };
    setSelectedOption3(selectedOption3);
    let error = { ...errors };
    error['type'] = "";
    setFormvalue(Formvalue);
    setErrors(error);
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      var pattern = new RegExp(addressRegex);

      if (isEmpty(value.name)) {
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.factory)) {
        error["factory"] = "Field is required";
      } else {
        error["factory"] = "";
      }
      if (isEmpty(value.router)) {
        error["router"] = "Field is required";
      }
      // else if (isEmpty(value.router?.match(pattern))) {
      //   error["router"] = "Enter Valid Address";
      // }
      else {
        error["router"] = "";
      }
      if (isEmpty(value.chainId)) {
        error["chainId"] = "Field is required";
      } else {
        error["chainId"] = "";
      }
      if (isEmpty(value.type)) {
        error["type"] = "Field is required";
      } else {
        error["type"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let isValidate = validation(formvalue);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await addNewExchange(formvalue);
      if (status == "success") {
        toast.success("Exchange Added Successfully");
        handleClose();
        getAllExchanges();
        setFormvalue({});
        setFormvalue({});
        setErrors({});
        setSelectedOption2();
      } else {
        toast.error("Exchange Already Added");
        handleClose();
        getAllExchanges();
        setFormvalue({});
        setFormvalue({});
        setErrors({});
        setSelectedOption2();
      }
      handleClose();
      getAllExchanges();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const HandleClose = async () => {
    handleClose();
    setFormvalue({});
    setErrors({});
    setSelectedOption2();
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
        ? "#DBBD7C"
        : isSelected
        ? "#917331"
        : isFocused
        ? "#DBBD7C"
        : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "1px solid #262626",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={HandleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Add Exchange</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={() => {
                HandleClose();
              }}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Exchage Name"
                  name="name"
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.name}</span>
            </div>
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Router</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Router Address"
                  name="router"
                  // value={formvalue?.router}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.router}</span>
            </div>{" "}
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Chain id</p>
              {/* <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2"> */}
              <Select
                onChange={(e) => handleChange2(e)}
                options={options}
                name="chainId"
                defaultValue="theme1"
                styles={stylesgraybg}
                isSearchable={false}
                classNamePrefix="customselect"
              />
              <span className="text-danger">{errors && errors.chainId}</span>
              {/* </div> */}
            </div>
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Factory</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Factory Address"
                  name="factory"
                  readOnly
                  value={formvalue.factory}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.factory}</span>
            </div>{" "}
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Type</p>
              {/* <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Type"
                  name="type"
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span>{errors && errors.type}</span>
              </div> */}

              <Select
                onChange={(e) => handleChange3(e)}
                options={options1}
                name="type"
                defaultValue="theme1"
                styles={stylesgraybg}
                isSearchable={false}
                classNamePrefix="customselect"
              />
              <span className="text-danger">{errors && errors.type}</span>
            </div>
            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddExchangeModal;
