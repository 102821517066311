import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { addNewPair } from "../actions/currency";
import { toastAlert } from "../lib/toastAlert";
import { getAllTokens } from "../actions/currency";
import Select from "react-select";
import toast from "react-hot-toast";

const AddPairModal = ({ show, handleClose, getAllPairs }) => {
  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const [tokens, setTokens] = useState();
  const options = [
    { value: 56, label: "56" },
    // { value: 137, label: "137" },
  ];

  useEffect(() => {
    getTokens();
  }, []);

  const getTokens = async () => {
    try {
      let { status, message, result } = await getAllTokens();
      setTokens(result);
    } catch (err) {
      console.log("getAllTokens_err", err);
    }
  };

  tokens?.map((val) => {
    val["label"] = val.name + "-" + val.chainId;
    val["value"] = val.name;
  });
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
        ? "#DBBD7C"
        : isSelected
        ? "#917331"
        : isFocused
        ? "#DBBD7C"
        : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };
  const stylesgraybg1 = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#5fe44b"
          : isSelected
          ? "#5fe44b"
          : isFocused
          ? "#5fe44b"
          : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "1px solid #262626",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  const [selectedOption1, setSelectedOption1] = useState();

  const handleChange1 = (e) => {
    console.log(
      e?.chainId != formvalue?.token2?.chainId,
      e?.address == formvalue?.token2?.address,
      "formvalue?.token2"
    );
    if (e?.address == formvalue?.token2?.address) {
      let Formvalue = { ...formvalue, ["token1"]: e };
      setFormvalue(Formvalue);
      let error = { ...errors };
      setSelectedOption1(e);
      error = { ...error, ["token1"]: "Token1 And Token2 Should'nt Same" };
      setErrors(error);
    } else if (
      formvalue?.token2?.chainId != undefined &&
      e?.chainId != formvalue?.token2?.chainId
    ) {
      let Formvalue = { ...formvalue, ["token1"]: e };
      setFormvalue(Formvalue);
      let error = { ...errors };
      setSelectedOption1(e);
      error = {
        ...error,
        ["token1"]: "Token1 And Token2 Must In Same Network",
      };
      setErrors(error);
    } else {
      let Formvalue = { ...formvalue, ["token1"]: e };
      setFormvalue(Formvalue);
      setSelectedOption1(e);
      let error = { ...errors };
      error = { ...error, ["token1"]: "" };
      error = { ...error, ["token2"]: "" };
      setErrors(error);
    }
  };

  const [selectedOption2, setSelectedOption2] = useState();

  const handleChange2 = (e1) => {
    console.log(formvalue?.token1, "formvalue?.token2");
    if (e1?.address == formvalue?.token1?.address) {
      let Formvalue = { ...formvalue, ["token2"]: e1 };
      setFormvalue(Formvalue);
      let error = { ...errors };
      setSelectedOption2(e1);
      error = { ...error, ["token2"]: "Token1 And Token2 Should'nt Same" };
      setErrors(error);
    } else if (
      formvalue?.token1?.chainId != undefined &&
      e1?.chainId != formvalue?.token1?.chainId
    ) {
      let Formvalue = { ...formvalue, ["token2"]: e1 };
      setFormvalue(Formvalue);
      let error = { ...errors };
      setSelectedOption2(e1);
      error = {
        ...error,
        ["token2"]: "Token1 And Token2 Must In Same Network",
      };
      setErrors(error);
    } else {
      let Formvalue = { ...formvalue, ["token2"]: e1 };
      setFormvalue(Formvalue);
      setSelectedOption2(e1);
      let error = { ...errors };
      error = { ...error, ["token1"]: "" };
      error = { ...error, ["token2"]: "" };
      setErrors(error);
    }
  };

  const [selectedOption3, setSelectedOption3] = useState();

  // const handleChange3 = async (selectedOption3) => {
  //   const { name, value } = selectedOption3;
  //   let Formvalue = { ...formvalue, ["chainId"]: value };
  //   setFormvalue(Formvalue);
  //   setSelectedOption3(selectedOption3);
  // };

  // const handleChange = (e) => {
  //   try {
  //     const { name, value } = e.target;
  //     let Formvalue = { ...formvalue, [name]: value };
  //     setFormvalue(Formvalue);
  //     setErrors(validation(Formvalue));
  //   } catch (err) {
  //     console.log(err, "handleChange");
  //   }
  // };

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.token1)) {
        error["token1"] = "Field is required";
      } else if (value.token1?.name == value.token2?.name) {
        error["token2"] = "Token1 And Token2 Should'nt Same";
      } else {
        error["token1"] = "";
      }
      if (isEmpty(value.token2)) {
        error["token2"] = "Field is required";
      } else if (value.token1?.name == value.token2?.name) {
        error["token2"] = "Token1 And Token2 Should'nt Same";
      } else {
        error["token2"] = "";
      }
      // if (isEmpty(value.chainId)) {
      //   error["chainId"] = "Field is required";
      // } else {
      //   error["chainId"] = "";
      // }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let userData = {
        // name: formvalue.name,
        // pair: formvalue.pair,
        // symbol: formvalue.symbol,
        token1: formvalue.token1,
        token2: formvalue.token2,
        // chainId: formvalue.chainId,
      };

      let isValidate = validation(userData);
      setErrors(isValidate);

      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await addNewPair(userData);
      console.log("🚀 ~ handleSubmit ~ status, message, result:", status, message, result)
      if (status == "success") {
        toast.success("Pair Added Successfully");
        handleClose();
        getAllPairs();
        setFormvalue({});
        setErrors({});
        selectedOption1("");
        selectedOption2("");
        selectedOption3("");
      } else {
        toast.error(message);
        handleClose();
        getAllPairs();
        setFormvalue({});
        setErrors({});
        selectedOption1("");
        selectedOption2("");
        // selectedOption3("");
      }
      handleClose();
      getAllPairs();
      setFormvalue({});
      setErrors({});
      selectedOption1("");
      selectedOption2("");
      // selectedOption3("");
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const HandleClose = async () => {
    handleClose();
    setFormvalue({});
    setErrors({});
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={HandleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Add Pair</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={() => {
                HandleClose();
              }}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            {/* <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span>{errors && errors.name}</span>
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Pair</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="pair"
                  name="pair"
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span>{errors && errors.pair}</span>
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Symbol</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="symbol"
                  name="symbol"
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span>{errors && errors.symbol}</span>
              </div>
            </div> */}

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Token 1 Address</p>

              <div className="rp_input_holder rounded-2 px-0">
                <Select
                  onChange={(e) => handleChange1(e)}
                  options={tokens}
                  name="plan"
                  defaultValue="theme1"
                  styles={stylesgraybg}
                  isSearchable={false}
                  classNamePrefix="customselect"
                />
              </div>
              <span className="text-danger">{errors && errors.token1}</span>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Token 2 Address</p>

              <div className="rp_input_holder rounded-2 px-0">
                <Select
                  onChange={(e) => handleChange2(e)}
                  options={tokens}
                  name="plan"
                  defaultValue="theme1"
                  styles={stylesgraybg}
                  isSearchable={false}
                  classNamePrefix="customselect"
                />
              </div>
              <span className="text-danger">{errors && errors.token2}</span>
            </div>

            {/* <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Chain Id</p>

              <Select
                onChange={(e) => handleChange3(e)}
                options={options}
                name="chainId"
                defaultValue="theme1"
                styles={stylesgraybg1}
                isSearchable={false}
                classNamePrefix="customselect"
              />
              <span className="text-danger">{errors && errors.chainId}</span>
            </div> */}

            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPairModal;
